import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"

const PartnerProgramInscripcion = () => {
  const data = useStaticQuery(graphql`
    query ImagesPartnerProgramInscripcion {
      image: allFile(
        filter: { relativeDirectory: { eq: "partners" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Inscríbete ya en nuestro Partner Program | Qualoom</title>
        <meta name="title" content="Inscríbete ya en nuestro Partner Program | Qualoom"/>
        <meta name="description" content="Ya puedes formar parte de esta comunidad de Partners y beneficiarte de todas las ventajas de este programa"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/partner-program/inscripcion/"/>
        <link rel="canonical" href="https://www.qualoom.es/partner-program/inscripcion/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/partner-program/inscripcion/"/>
        <meta property="og:title" content="Inscríbete ya en nuestro Partner Program | Qualoom"/>
        <meta property="og:description" content="Ya puedes formar parte de esta comunidad de Partners y beneficiarte de todas las ventajas de este programa"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3MRtNIyAwtyGPXOaftMby9/5717580e7dcee1881c0f956118deb692/tw_cards_partners.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/partner-program/inscripcion/"/>
        <meta property="twitter:title" content="Inscríbete ya en nuestro Partner Program | Qualoom"/>
        <meta property="twitter:description" content="Ya puedes formar parte de esta comunidad de Partners y beneficiarte de todas las ventajas de este programa"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3MRtNIyAwtyGPXOaftMby9/5717580e7dcee1881c0f956118deb692/tw_cards_partners.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/partner-program/inscripcion/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Partner Program - Inscripción", "item": {"@id": "https://www.qualoom.es/partner-program/inscripcion/", "name": "Partner Program - Inscripción"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-partner">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>¿Quieres ser nuestro nuevo Partner?</h1>
              <p>Descubre los requisitos y recursos de los miembros. Si quieres formar parte de nuestro Partner Program debes rellenar el formulario de inscripción </p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="partner-main">
        <div className="partner-main-content">
          <h2>Ya puedes formar parte de nuestro Partner Program</h2>
          <p>Formar parte de este programa te permitirá generar nuevas oportunidades de negocio. Para ello, descubre <b>todos los detalles sobre el <a href="/partner-program/">Partner Program</a> así como los <a href="/partner-program/recursos/">materiales disponibles</a> para todos los miembros.</b></p>
          <p>Para solicitar tu adhesión, envíanos un correo a <a href="mailto:contacto@qualoom.es?subject=Qualoom · Partner Program · Estoy interesado en convertirme en Partner &body=Hola, %0A%0A Estoy interesado en convertirme en Partner.%0A%0A Les remito los siguientes datos: %0A%0A%09 Nombre de la empresa: %0A%0A%09 Tamaño de la organización: %0A%0A%09 Tipo de empresa: %0A%0A%09 Contacto de la persona que realiza la petición: %0A%0A%09%09 Nombre y apellidos: %0A%0A%09%09 Correo electrónico: %0A%0A%09%09 Teléfono de contacto: %0A%0A%09 Localización de la empresa: %0A%0A%0A Saludos.">contacto@qualoom.es</a> con los siguientes datos:</p>
          <p>
            <ul>
              <li>Nombre de la empresa</li>
              <li>Tamaño de la organización (nº de empleados)</li>
              <li>Tipo de empresa (salud, logística, retail, tecnología, marketing, legal, etc.)</li>
              <li>Contacto de la persona que realiza la petición:</li>
              <ul><li>Nombre y apellidos</li><li>Correo electrónico</li><li>Teléfono de contacto</li></ul>
              <li>Localización de la empresa</li>
            </ul>
          </p>
          <div className="partner-program-only-button"><a href="mailto:contacto@qualoom.es?subject=Qualoom · Partner Program · Estoy interesado en convertirme en Partner&body=Hola, %0A%0A Estoy interesado en convertirme en Partner.%0A%0A Les remito lso siguientes datos:%0A%0A%09 Nombre de la empresa: %0A%0A%09 Tamaño de la organización: %0A%0A%09 Tipo de empresa: %0A%0A%09 Contacto de la persona que realiza la petición: %0A%0A%09%09 Nombre y apellidos: %0A%0A%09%09 Correo electrónico: %0A%0A%09%09 Teléfono de contacto: %0A%0A%09 Localización de la empresa: %0A%0A%0A Saludos." className="servicios-button">Quiero formar parte del Partner Program</a></div>
        </div>
      </div>
      <div className="partner-main gray">
        <div className="partner-main-content">
          <h2>¿Por qué ser miembro de esta comunidad?</h2>
          <p>Es la oportunidad para compartir desarrollo de negocio e ingresos entre los miembros del canal a través de la generación de demanda conjunta siguiendo diferentes estratégicas y en concreto aquella que mejor se ajuste al perfil de cada miembro.</p>
          <p>A nivel organizativo las implicaciones directas que tiene el formar parte de este programa son:</p>
          <p>
            <ul>
              <li><b>Definición del perfil de empresa y servicios del potencial partner</b> que serán publicados en la web de Qualoom, en medios y a través de aquellas actividades que puedan requerir contar con el perfil de empresa miembro del programa</li>
              <li><b>Identificación y Análisis de fortalezas y debilidades</b> con el fin de definir una estrategia de venta de producto o servicio</li>
              <li><b>Definición conjunta de una estrategia con objetivos planificados y medibles</b></li>
              <li><b>Aportación común de recursos materiales o económicos</b> para desarrollar actividades de capatación, fidelización, procesos de prospección, así como la preparación de ofertas comerciales</li>
              <li><b>Feedback y seguimiento periódico</b> con el correspondiente Partner Program Manager</li>
          </ul></p>
          <p>Siguiendo estos pasos se generarán nuevas y fuertes alianzas entre los partners del canal que aportarán más y mayores ventas además de clientes cada vez más fidelizados, aumentado el valor de marca y la reputación en el ecosistema TI.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PartnerProgramInscripcion
